<template>
	<b-card
		border-variant="mmi-blue"
		header-bg-variant="mmi-blue"
		header-text-variant="white"
		class="d-flex flex-row"
	>
		<template #header>
			<h6 class="m-0 p-2 text-center" @click="toggle">LEGENDA</h6>
		</template>
		<div class="d-flex align-items-center">
			<div>
				<table class="table table-borderless m-0">
					<thead>
						<tr>
							<th>SIGLA</th>
							<th>DESCRIÇÃO</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>OEE</td>
							<td>Overall Efficiency And Effectiveness</td>
						</tr>
						<tr>
							<td>Prod.</td>
							<td>Produtividade</td>
						</tr>
						<tr>
							<td>UR</td>
							<td>Utilization Ratio</td>
						</tr>
						<tr>
							<td>WR</td>
							<td>Working Ratio</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div id="tb-estados">
				<table class="table table-borderless m-0">
					<thead>
						<tr>
							<th colspan="2">TIPOS DE ESTADO</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td><div class="bg-success"></div></td>
							<td>Rodando</td>
						</tr>
						<tr>
							<td><div class="bg-primary"></div></td>
							<td>{{aliasPredisposto}}</td>
						</tr>
						<tr>
							<td><div class="bg-warning"></div></td>
							<td>Parada Programada</td>
						</tr>
						<tr>
							<td><div class="bg-danger"></div></td>
							<td>Parada Não Programada</td>
						</tr>
						<tr>
							<td><div class="bg-dark"></div></td>
							<td>Dados Históricos</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</b-card>
</template>

<script>
	export default {
		data () {
			return {
				aliasPredisposto: localStorage.getItem("aliasPredisposto") || "Predisposto"
			};
		},
		methods: {
			toggle () {
				this.$emit("toggle");
			}
		}
	};
</script>

<style lang="scss" scoped>
	.card {
		width: 530px;

		.card-header {
			padding: 0;
			border: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			writing-mode: vertical-lr;
			text-orientation: upright;

			h6:hover {
				cursor: pointer;
			}
		}

		.card-body {
			padding: 0.25rem 0.75rem;
		}
	}

	.table th, .table td {
		padding: 0.25rem;
	}

	.border-mmi-blue {
		border-color: #6dcff6 !important;
	}

	.bg-mmi-blue {
		background-color: #6dcff6 !important;
	}

	.bg-dark {
		background-color: #767070 !important;
	}

	.bg-success {
		background-color: #06B558 !important;
	}

	.bg-primary {
		background-color: #406ADA !important;
	}

	.bg-warning {
		background-color: #FAAF40 !important;
	}

	.bg-danger {
		background-color: #EC1C24 !important;
	}

	#tb-estados {
		margin-left: 0.75rem;
		padding-left: 0.75rem;
		border-left: 1px solid rgba(0, 0, 0, 0.1);

		td:first-child {
			div {
				width: calc(28px - 0.25rem);
				height: calc(28px - 0.25rem);
				border-radius: 2px;
			}
		}
	}
</style>
